import React from 'react';
import { useHistory } from 'react-router-dom';
import logo from '../../assets/img/logo.png';

export default function Header() {
    const history = useHistory();

    return (
        <header>
            <div className="container col-sm-12 my-3">
                <div style={{ marginBottom: 5 }}>
                    <div className="row">
                        <div className="col-sm-7 d-inline-flex" style={{ cursor: 'pointer' }}>
                            <span style={{ paddingRight: 10 }}>
                                <a href="/"><img src={logo} className="img-fluid" style={{ width: 33 }} alt="Logo" /></a>
                            </span>
                            <span className="fs-4 ml-2" style={{ color: 'white', marginTop: 7 }}>
                                <a className="py-2 text-white text-decoration-none" href="/">CIPayScan</a>
                            </span>
                        </div>
                        <div className="col-sm-5 text-right" style={{ marginTop: 10 }}>
                            <div className="d-inline-flex" >
                                <div style={{cursor: "pointer"}}>
                                    <span style={{ padding: 5 }}><a className="py-2 text-white text-decoration-none" href="/">Home |</a></span>
                                    <a className="py-2 text-white text-decoration-none" href="#" style={{ padding: 5 }}>Developers |</a>
                                    <a className="py-2 text-white text-decoration-none" style={{ padding: 5 }} onClick={() => history.push("/phisical-contract")}>Phisical Contract |</a>
                                    <a className="py-2 text-white text-decoration-none" href="https://cipay.com.br" style={{ padding: 5 }}>Cipay |</a>
                                    <a className="py-2 text-white text-decoration-none" href="https://internet-blocking.cipay.com.br/" style={{ padding: 5 }}>Abra sua Conta</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </header>
    );
}
