import react, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { getContractSign, getTokenContractSign, getPhisicalContractSign } from '../../services/index.js';
import Linha from "./components/linha.js";

import ModalHandle from "../components/modal/index.jsx";

import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';
import PropTypes from 'prop-types';
import Modal from '@mui/material/Modal';

export default ({ isToken, match }) => {
    const history = useHistory();
    const [historico, setHistorico] = useState([])
    const [open, setOpen] = useState(false);
    const [noTransaction, setNoTransaction] = useState(false)
    const [valueTabs, setValueTabs] = useState(0);
    const [smartContract, setSmartContract] = useState("");
    const [isModalOpen, setIsModalOpen] = useState(false);



    const [contractSign, setContractSign] = useState({
        asset: {},
        assetBalance: {},
        contractAssets: {},
        fee: {},
        feeNetworking: {},
        saque: []
        
    });
    

    useEffect(_ => {
       
        const ids = isTokenID(match.params.id);
        setSmartContract(match.params.id);
        console.log(smartContract, "smartContract match.params.id useEffect")
        
        if(ids === "contract"){
            getContractSignExplorer(match.params.id);
            console.log(contractSign, "contractSign per contract");
            console.log(historico, "contractSign transaction per contract");
            
        }else{
            const cdAsset = atob(match.params.idAsset);
            getContractSignExplorerToken(cdAsset);
            console.log(contractSign, "contractSign per token");
            console.log(historico, "contractSign transaction per token");
        }

        

    }, [smartContract])

    function propsTabs(index) {
        return {
             id: `simple-tab-${index}`,
             'aria-controls': `simple-tabpanel-${index}`,
        };
    }

    const isTokenID = (id) => {
        if(id.includes("contractCiPay")){
            return "contract"
        }

        return "token"
    }

    function CustomTabPanel(props) {
        const { children, value, index, ...other } = props;
   
        return (
             <div
                  role="tabpanel"
                  hidden={value !== index}
                  id={`simple-tabpanel-${index}`}
                  aria-labelledby={`simple-tab-${index}`}
                  {...other}
             >
                  {value === index && (

                    <Typography>{children}</Typography>

                  )}
             </div>
        );
   }
   
   CustomTabPanel.propTypes = {
        children: PropTypes.node,
        index: PropTypes.number.isRequired,
        value: PropTypes.number.isRequired,
   };
   

    const handleChangeTabs = (event, newValue) => {
        setValueTabs(newValue);
    }

    const handleOpenLinkContractPhisical = async () => {        
        setIsModalOpen(true);
        const contractSign = await getPhisicalContractSign(smartContract);
        const link = JSON.parse(contractSign.data.content);

        if(link.Url === ""){
            return;
        }

        window.open(link.Url, "_blank");
        handleCloseModal();
    }

    const handleCloseModal = () => {
        setIsModalOpen(false); // Fecha o modal
    };

    const getContractSignExplorer = async (sign) => {
        //debugger;
        const contractSignResult = await getContractSign(sign)
        const result = JSON.parse(contractSignResult.data.content);
        const transaction = [];
        
        setContractSign({
            asset: result.content.asset,
            assetBalance: result.content.assetBalance,
            contractAssets: result.content.contractAssets,
            fee: result.content.fee,
            feeNetworking: result.content.feeNetworking,
        });

        for (let index = 0; index < result.saque.length; index++) {
            //debugger;
            transaction.push(<Linha key={index} h={result.saque[index]} isToken={isToken} />)
        }

        if(transaction.length === 0){
            setNoTransaction(true);
        }else{
            setNoTransaction(false);
        }

        setHistorico(transaction)
 
    }

    const getContractSignExplorerToken = async (sign) => {

        const contractSignResult = await getTokenContractSign(sign)
        const result = JSON.parse(contractSignResult.data.content);
        //console.log(result, "result getContractSignExplorerToken")
        const transaction = [];
        setContractSign({
            asset: result.content.asset,
            assetBalance: result.content.assetBalance,
            contractAssets: result.content.contractAssets,
            fee: result.content.fee,
            feeNetworking: result.content.feeNetworking,
        });

        for (let index = 0; index < result.saque.length; index++) {
            transaction.push(<Linha key={index} h={result.saque[index]} isToken={isToken} />)
            //console.log(result.saque[index], "result.saque[index] getContractSignExplorerToken")
        }

        if(transaction.length === 0){
            setNoTransaction(true);
        }else{
            setNoTransaction(false);
        }

        setHistorico(transaction)
 
    }

    return <>
        <div className="container">

            <div className="row">
                <div className="col-auto">
                    <img style={{ borderRadius: 15, width: 50 }} src={contractSign?.asset?.urlAsset} />
                </div>
                {
                    isToken ?
                        <div className="col-sm-8 mt-2" style={{color: 'white'}}><h4>Token {contractSign?.asset?.nmAsset}</h4> </div>
                        :
                        <div className="col-sm-9 mt-2"><h5 style={{color: 'white'}}>Address {contractSign?.contractAssets?.adressContract}</h5></div>
                }
            </div>
            <div className="row mt-3">
                <div className="col-sm-4">
                    <div className="card">
                        <div className="card-body">
                            <h5 className="card-title">Overview</h5>
                            <div className="row">
                                {
                                    isToken ?
                                        <>
                                            <div className="col-sm-12" style={{fontSize: 15}}>
                                                MAX TOTAL SUPPLY
                                            </div>
                                            <div className="col-sm-12" style={{fontSize: 14}}>
                                                {Intl.NumberFormat('pt-BR').format(contractSign?.assetBalance.balance)} <i className="fa-solid fa-circle-info"></i>
                                            </div>
                                            <div className="col-sm-12" style={{fontSize: 15}}>
                                                TOTAL TRANSFERS
                                            </div>
                                            <div className="col-sm-12" style={{fontSize: 14}}>
                                                {historico?.length} <i className="fa-solid fa-circle-info"></i>
                                            </div>
                                        </>
                                        : 
                                        <>
                                            <div style={{paddingBottom: 96}}>                                  
                                                <div className="col-sm-12" style={{fontSize: 14}}>
                                                    MAX TOTAL SUPPLY {Intl.NumberFormat('pt-BR').format(contractSign?.assetBalance.balance)}
                                                </div>
                                                <div className="col-sm-12" style={{fontSize: 15}}>
                                                    TOTAL TRANSFERS
                                                </div>
                                                <div className="col-sm-12" style={{fontSize: 14}}>
                                                    {historico?.length} <i className="fa-solid fa-circle-info"></i>
                                                </div>
                                            </div>
                                        </>
                                        
                                }
                            </div>
                            <div className="row">
                                    
                            </div>
                        </div>
                    </div>
                </div>
                {
                    isToken ?
                    <div className="col-sm-8">
                        <div className="card" style={{paddingBottom: 42}}>
                            <div className="card-body">
                                <h5 className="card-title">Other Info</h5>
                                <div className="row">
                                    <div className="col-12" style={{fontSize: 14}}>
                                        TOKEN CONTRACT
                                    </div>
                                    <div className="col-12">
                                        <i className="fa-regular fa-file-lines"></i> 
                                        <span style={{margin:5}} className="customLinks" onClick={_ => {
                                            history.push({
                                                pathname: "/contrato/" + contractSign?.contractAssets?.adressContract,
                                            })
                                        }}>
                                            {contractSign?.contractAssets?.adressContract} 
                                        </span> 
                                        <i className="fa-regular fa-clipboard"></i>
                                    </div>
                                </div>
                                <div className="row">

                                </div>
                            </div>
                        </div>
                    </div> 
                    :<>
<div className="col-sm-8">
    <div className="card">
        <div className="card-body">
            <h5 className="card-title">Information Contract</h5>
            <div className="row">
                <div className="col-sm-6 align-items-center">
                    <div className="d-flex align-items-center" style={{ cursor: 'pointer', marginRight: '15px' }} onClick={handleOpenLinkContractPhisical}>
                        <i className="fa-solid fa-download" style={{ marginRight: 7 }}></i>
                        <p style={{ marginBottom: 0 }}>Physical contract</p>
                    </div>
                    <div style={{ cursor: 'pointer' }}>
                        <i className="fa-solid fa-globe" style={{ marginRight: 7 }}></i>
                        Video
                    </div>
                </div>
                <div className="col-sm-6">
                    <div className="row" style={{padding:0, margin:0}}>
                        <div className="col-sm-6 d-flex justify-content-between align-items-center">
                            <h6 className="card-title">Projected profitability</h6>
                            <p>0</p>
                        </div>
                    </div>
                    <div className="row" style={{padding:0, margin:0}}>
                        <div className="col-sm-6 d-flex justify-content-between align-items-center">
                            <h6 className="card-title">Minimum funding</h6>
                            <p>0</p>
                        </div>
                        <div className="col-sm-6 d-flex justify-content-between align-items-center">
                            <h6 className="card-title">Maximum Capture</h6>
                            <p>0</p>
                        </div>
                    </div>
                    <div className="row" style={{padding:0, margin:0}}>
                        <div className="col-sm-6 d-flex justify-content-between align-items-center">
                            <h6 className="card-title">Term</h6>
                            <p>0</p>
                        </div>
                        <div className="col-sm-6 d-flex justify-content-between align-items-center">
                            <h6 className="card-title">Risk</h6>
                            <p>0</p>
                        </div>
                    </div>
                    <div className="row" style={{padding:0, margin:0}}>
                        <div className="col-sm-6 d-flex justify-content-between align-items-center">
                            <h6 className="card-title">Start date</h6>
                            <p>0</p>
                        </div>
                        <div className="col-sm-6 d-flex justify-content-between align-items-center">
                            <h6 className="card-title">End of capture</h6>
                            <p>0</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

                    </>
                }

            </div>
            <div className="row mt-3">
                <div className="col-sm-12">
                    <div className="card">
                        <div className="card-body">
                                <Tabs value={valueTabs} onChange={handleChangeTabs} aria-label="basic tabs example">
                                    <Tab label="Transaction" {...propsTabs(0)} />
                                    <Tab label="Contract" {...propsTabs(1)} />
                                    <Tab label="Info" {...propsTabs(2)} />
                                </Tabs>
                                <CustomTabPanel value={valueTabs} index={0}>
                                    <div className="container">
                                        <table className="table" style={{marginTop: 20}}>
                                            <thead>
                                                <tr>
                                                    <th scope="col"><i className="fa-regular fa-circle-question"></i></th>
                                                    <th scope="col">Transaction Hash</th>
                                                    {
                                                        !isToken && 
                                                            <th scope="col">Block</th>
                                                    }
                                                    <th scope="col">From</th>
                                                    <th scope="col">To</th>
                                                    <th scope="col">Activity</th>
                                                    <th scope="col">Value</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    noTransaction && 
                                                    <>
                                                        <td colSpan="6" className="text-center">No Transactions</td>
                                
                                                    </>
                                                }

                                                {
                                                    historico.length === 0 && noTransaction === false? 
                                                    <tr>
                                                        <td colSpan="6" className="text-center">
                                                            <div style={{ display: 'flex', 
                                                                            justifyContent: 'center', 
                                                                            alignItems: 'center', 
                                                                            height: '100px' 
                                                                        }}>
                                                                <CircularProgress size={55} />
                                                            </div>
                                                        </td>
                                                    </tr> : historico 
                                                }
                                            </tbody>
                                        </table>
                                    </div>
                                </CustomTabPanel>
                                <CustomTabPanel value={valueTabs} index={1}>
                                    
                                    <div className="container" style={{paddingTop: 20}}>
                                        <table className="table" style={{marginTop: 20}}>
                                            <thead>
                                                <tr><td colSpan="6" className="text-center">Content Private</td></tr>
                                            </thead>
                                            
                                        </table>
                                    </div>
                                </CustomTabPanel>
                                <CustomTabPanel value={valueTabs} index={2}>
                                <div className="container" style={{paddingTop: 20}}>
                                        <table className="table" style={{marginTop: 20}}>
                                            <thead>
                                                <tr><td colSpan="6" className="text-center">Info</td></tr>
                                            </thead>
                                            
                                        </table>
                                    </div>
                                </CustomTabPanel>
                                <ModalHandle 
                                    open={isModalOpen} 
                                    handleClose={handleCloseModal}
                                    body={
                                        <>
                                            <CircularProgress />
                                        </>
                                    }  
                                />

                        </div>
                    </div>
                </div>
            </div>
        </div>
    </>
}