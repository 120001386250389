import react, { useEffect, useState, useRef } from 'react'
import { useHistory } from "react-router-dom";
import { Button, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Typography, Avatar  } from '@mui/material';
import VerifiedUserIcon from '@mui/icons-material/VerifiedUser';
import CancelIcon from '@mui/icons-material/Cancel';
import LinearProgress from '@mui/material/LinearProgress';
import imageBlock from '../../assets/img/explorer.png';

import { getValidatePhisicalContractOnChain } from '../../services/index.js';

export default _ => {
    const history = useHistory();
    const [file, setFile] = useState(null);
    const [fileName, setFileName] = useState('');
    const [showTable, setShowTable] = useState(false);
    const [dynamicPaddingBottom, setDynamicPaddingBottom] = useState(100);
    const [progress, setProgress] = useState(0);
    const [buffer, setBuffer] = useState(10);
    const progressRef = useRef(() => {});
    const [showProgressBar, setShowProgressBar] = useState(false);
    const [iconTrustContract, setIconTrustContract] = useState(false);
    const [showImg, setShowImg] = useState(false);

    const [phiscalContract, setPhiscalContract] = useState({
        hash: "",
        smartContract: "",
        nameOfToken: "",
        iconLogoToken : ""
    });
  
    const handleFileChange = async(event) => {
      //debugger;
      const timer = setInterval(() => {
        progressRef.current();
        const diff = Math.random() * 70;
        const diff2 = Math.random() * 70;
        setProgress(progress + diff);
        setBuffer(progress + diff + diff2);
      }, 500);

      const selectedFile = event.target.files[0];

      if (selectedFile) {
        setFile(selectedFile);
        setFileName(selectedFile.name);
        setShowImg(true);
        setShowProgressBar(true);
        const hashContractSign = await getValidatePhisicalContractOnChain(event.target.files[0])
        const jsonContract = JSON.parse(hashContractSign.data.content)

        if(jsonContract.msg === "Contract hash was not found !"){
            setIconTrustContract(false);
            setShowProgressBar(false);
            setShowTable(true);
            setPhiscalContract({
                hash: "",
                smartContract: "",
                nameOfToken: "",
                iconLogoToken: ""
            });
            clearInterval(timer);
            setShowImg(true);
            return;
        }

       if(hashContractSign.status === 200){
        console.log(jsonContract,"jsonContract");

        setPhiscalContract({
            hash: jsonContract.content.hashPhisicalContract,
            smartContract: jsonContract.content.contractSign.contractAssets.adressContract,
            nameOfToken: jsonContract.content.contractSign.asset.assetAlias,
            iconLogoToken: jsonContract.content.contractSign.asset.urlAsset
        });

        setShowProgressBar(false);
        setShowImg(true);
        setShowTable(true);
        setIconTrustContract(true);
        setDynamicPaddingBottom(0);
        clearInterval(timer);
       }

      }
    };
  
    const handleUpload = () => {
      if (file) {
        // Aqui você pode adicionar a lógica para enviar o arquivo para o servidor
        console.log('Arquivo pronto para upload:', file);
      }
    };
  

    return <>
        <div className='container' >
            <div style={{marginTop: 50}}>
                <div className="card" style={{paddingBottom: dynamicPaddingBottom, paddingTop: 10}}>
                    <div className='text-center my-4' style={{marginTop: 20}}>

                        <h5 className="">Verificação de Contrato Fisico Anexado em Blockchain</h5>
                    </div>
                    <div className='text-justify container col-sm-9'>
                        <p className="" style={{ textAlign: 'justify' }}>A integração de contratos físicos na blockchain representa um avanço significativo na forma como garantimos a 
                            autenticidade e a segurança de documentos tradicionais. Com essa tecnologia inovadora, 
                            a Cipay possibilita que contratos físicos sejam anexados à blockchain, 
                            gerando um registro digital imutável que valida e protege o documento contra fraudes e alterações. 
                            Isso permite que empresas e indivíduos verifiquem, de forma eficiente e transparente, 
                            a integridade dos contratos, automatizando processos complexos e simplificando auditorias.</p>
                        <Stack spacing={2} alignItems="left">
                            <input
                                accept="*/*"
                                id="file-input"
                                type="file"
                                style={{ display: 'none' }}
                                onChange={handleFileChange}
                            />
                            <div className='row'>
                                <div className=''>
                                <div className='row'>
                                    <div className='col-sm-6'>
                                        {/* Deixe este espaço vazio ou adicione conteúdo */}
                                    </div>
                                    <div className='col-sm-6 d-flex justify-content-end' style={{marginBottom: 50}}>
                                        <label htmlFor="file-input">
                                        <Button variant="contained" component="span">
                                            Escolher Arquivo
                                        </Button>
                                        </label>
                                    </div>
                                </div>

                                    <div className='container mb-5' >
                                        {
                                            showTable && (   
                                            <Paper elevation={10}>
                                                <TableContainer>
                                                    <Table>
                                                        <TableHead>
                                                        <TableRow>
                                                            <TableCell><Typography variant="h6">File</Typography></TableCell>
                                                            <TableCell><Typography variant="h6">{fileName}</Typography></TableCell>
                                                        </TableRow>
                                                        </TableHead>
                                                        <TableBody>
                                                        <TableRow>
                                                            <TableCell>Hash</TableCell>
                                                            <TableCell>{phiscalContract.hash}</TableCell>
                                                        </TableRow>
                                                        <TableRow style={{cursor: "pointer"}}>
                                                            <TableCell>SmartContract</TableCell>
                                                            <TableCell sx={{ textDecoration: 'underline', color: "blue" }} onClick={()=>history.push({
                                                                    pathname: "/contrato/" + phiscalContract.smartContract,
                                                                })}>{phiscalContract.smartContract}
                                                            </TableCell>
                                                        </TableRow>
                                                        <TableRow>
                                                        <TableCell>Name of Token</TableCell>
                                                        <TableCell style={{padding: 0}}>
                                                            <div className="d-flex align-items-center">
                                                                {
                                                                    iconTrustContract&&(<Avatar alt="Remy Sharp" src={phiscalContract.iconLogoToken} />)
                                                                }
                                                                <span style={{ marginLeft: "10px" }}>{phiscalContract.nameOfToken}</span>
                                                            </div>
                                                        </TableCell>
                                                        </TableRow>
                                                        <TableRow>
                                                            <TableCell>Physical Contract</TableCell>
                                                            <TableCell>
                                                                {
                                                                    iconTrustContract ? <>
                                                                        Real 
                                                                        <VerifiedUserIcon sx={{ color: '#4caf50', marginLeft: 1 }} />
                                                                    </>:<>
                                                                        Not Found
                                                                        <CancelIcon sx={{ color: 'red', marginLeft: 1 }}/>
                                                                    </>
                                                                }
                                                            </TableCell>
                                                        </TableRow>
                                                        </TableBody>
                                                    </Table>
                                                </TableContainer>
                                            </Paper>                                 
                                            )
                                        }
                                        {showProgressBar &&(

                                            <LinearProgress variant="buffer" value={progress} valueBuffer={buffer} />
                                        )}

                                        {
                                            !showImg&&(

                                            <div className='text-center'>
                                                <img src={imageBlock} className="img-fluid col-sm-7"  alt="Logo" />
                                            </div>
                                            )
                                        }

                                    </div>
                                </div>

                            </div>


                        </Stack>
                    </div>
                </div>
            </div>
        </div>
    </>
}