import { useEffect } from "react";
import { useState } from "react";
import { useLocation } from "react-router-dom";

export default _ => {
    const location = useLocation();
    //debugger;

    const [hashTransaction, setHashTransaction] = useState("");
    const [lastBlocks, setLastBlocks] = useState();
    const [isActivity, SetIsActivity] = useState(false);
    const [activity, SetActivity] = useState("");
    const { data } = location

    useEffect(()=>{
        debugger;
        removerPathName(location.pathname);

        if(data !== undefined){
            if(data.transaction.hashReceiver === "" || data.transaction.hashReceiver === null){
                SetIsActivity(true);
                SetActivity("Created SmartContract");
            }
        }

        
    },[])

    const removerPathName = (path) => {
        setHashTransaction(path.replace("/transacao/",""));
    }



   

    return <>
        <div className="display-6 container" style={{fontSize: 27, color: 'white', marginBottom: 15}}>
            Transaction  Details
        </div>
        <div className="container">

            <div className="row">
        </div>
            <div className="card">
                <div className="card-body">
                    <div className="row">
                        <div className="row">
                            <div className="col-sm-2 my-3">
                                <i className="fa-regular fa-circle-question"></i> Transaction Hash:
                            </div>
                            <div className="col-sm-10 my-3">
                                {hashTransaction}
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-sm-2 my-3">
                                <i className="fa-regular fa-circle-question"></i> Status:
                            </div>
                            <div className="col-sm-10 my-3">
                                <span className="badge bg-success bg-opacity-50 border border-success border-opacity-25 fw-medium text-start text-wrap py-1.5 px-2" data-bs-toggle="tooltip" data-bs-trigger="hover" data-bs-placement="top"><i class="fa fa-check-circle me-1"></i>Success</span>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-sm-2 my-3">
                                <i className="fa-regular fa-circle-question"></i> Block:
                            </div>
                            <div className="col-sm-10 my-3">
                                {data?.block}
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-sm-2 my-3">
                                <i className="fa-regular fa-circle-question"></i> Timestamp:
                            </div>
                            <div className="col-sm-10 my-3">
                                {data?.transaction.insertDate}
                            </div>
                        </div>
                    </div>
                    <hr />
                    <div className="row">
                        <div className="row">
                            <div className="col-sm-2 my-3">
                                <i className="fa-regular fa-circle-question"></i> From:
                            </div>
                            <div className="col-sm-10 my-3">
                                {data?.transaction.hashSender}
                            </div>
                        </div>
                        <div className="row">
                            {/* <div className="col-sm-2 my-3">
                                <i className="fa-regular fa-circle-question"></i> Interacted With (To):
                            </div> */}
                            { !isActivity ? (    
                                <>
                                    <div className="col-sm-2 my-3">
                                        <i className="fa-regular fa-circle-question"></i> Interacted With (To):
                                    </div>          
                                    <div className="col-sm-10 my-3">
                                        {data?.transaction.hashReceiver}
                                    </div>
                                </>              
                            ): (
                                <>
                                    <div className="col-sm-2 my-3">
                                        <i className="fa-regular fa-circle-question"></i> Activity Transaction:
                                    </div> 
                                    <div className="col-sm-10 my-3">
                                        {activity}
                                    </div>
                                </>
                            )

                            }
          
                        </div>
                    </div>
                    <hr />
                    <div className="row">
                        <div className="row">
                            <div className="col-sm-2 my-3">
                                <i className="fa-regular fa-circle-question"></i> Value:
                            </div>
                            <div className="col-sm-10 my-3">
                                {Intl.NumberFormat('pt-BR').format(data?.transaction.cointQuantity)}
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-sm-2 my-3">
                                <i className="fa-regular fa-circle-question"></i> Transaction Fee:
                            </div>
                            <div className="col-sm-10 my-3">
                                {Math.random() * (0.000999999 - 0.000000001) + 0.000000001}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </>
}